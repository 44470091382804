import * as Turbo from '@hotwired/turbo'
import 'sneat-bootstrap-html-admin-template-free/assets/vendor/libs/jquery/jquery.js'
import 'sneat-bootstrap-html-admin-template-free/assets/vendor/libs/popper/popper.js'
import 'sneat-bootstrap-html-admin-template-free/assets/vendor/js/bootstrap.js'

import 'sneat-bootstrap-html-admin-template-free/assets/vendor/js/helpers.js'
import 'sneat-bootstrap-html-admin-template-free/assets/vendor/js/menu.js'

import "trix"
import "@rails/actiontext"

import '../apps/room_plan'
import '../apps/ticketshop_order_new'
import '../apps/ticketshop_order_checkout'
import '../apps/speech_assistent'
import '../apps/ticket_scan'
import '../apps/calendar'
import '../apps/staff_manager'
import '../apps/staff_invitation'
import '../apps/staff_agenda'
import '../apps/ticketshop_domain'
import '../apps/company_select'
import '../apps/orderbird'
import "chartkick/chart.js"
import "./confetti.js"
import consumer from "./consumer"
import fileDownload from 'js-file-download';

Turbo.start()
document.addEventListener("turbo:load", onLoad);
document.addEventListener("turbo:submit-end", onLoad);
let menu, animate;
function onLoad() {

  // Initialize menu
  //-----------------

  let confetti = document.querySelectorAll('#confetti');
  confetti.forEach(function (element) {
    window.confetti({
      particleCount: 500,
      spread: 500,
      origin: { y: 0.1 }
    });
  })

  let layoutMenuEl = document.querySelectorAll('#layout-menu');
  layoutMenuEl.forEach(function (element) {
    menu = new Menu(element, {
      orientation: 'vertical',
      closeChildren: false
    });
    // Change parameter to true if you want scroll animation
    window.Helpers.scrollToActive((animate = false));
    window.Helpers.mainMenu = menu;
  });

  function checkCheckSwitchs(input){
    document.querySelectorAll('.content_'+input.id).forEach(content => {
      if (input.checked){
        content.classList.remove("hidden")
      }else{
        content.classList.add("hidden")
      }
    })
    document.querySelectorAll('.content_negativ_'+input.id).forEach(content => {
      if (input.checked){
        content.classList.add("hidden")
      }else{
        content.classList.remove("hidden")
      }
    })
  }

  function asyncButtons() {
    let asyncButtons = document.querySelectorAll('.async-button');
      asyncButtons.forEach(e => {
        e.addEventListener('click', (event) => {
          event.preventDefault();

          let params

          if (document.getElementById(e.id.replace("-btn", "-input"))){
            params = JSON.stringify({input: document.getElementById(e.id.replace("-btn", "-input")).value})
          }
          
          fetch(e.dataset.url, {
            method: e.dataset.method || 'DELETE',
            body: params,
            headers: {
              "X-CSRF-Token": e.dataset.authenticityToken,
              "Content-Type": "application/json"
            }
          }).then(response => {
            if (response.status == 305){
              location.reload()
            } else if (response.redirected || response.status == 301) {
              window.location.href = response.url;
            }
          })
        })
      })
  }
  asyncButtons()

  function btnInlineRemove() {

    let btnInlineRemove = document.querySelectorAll('.btn-remove-inline');
    btnInlineRemove.forEach(button => {
      button.addEventListener('click', () => {
        let element = document.querySelector(button.dataset.remove);
        let a = element.parentElement.parentElement.querySelector("a")
        a.setAttribute("data-bs-toggle", "")

        setTimeout((x => {
          a.setAttribute("data-bs-toggle", "dropdown")
        }), 200);
        document.querySelector("#call-counter").innerHTML = parseInt(document.querySelector("#call-counter").innerHTML) - 1
        element.remove()
      })
    })
  }
  btnInlineRemove()

  let toastContainer = document.querySelectorAll('.toast-container');
  toastContainer.forEach(container => {
    consumer.subscriptions.create({ channel: "ToastChannel", token: container.dataset.token }, {
      received(data) {
        fetch("/crm/call_box").then(response => response.json()).then(data => {
          document.querySelector('#call-box').innerHTML = data.html;
          btnInlineRemove()
          asyncButtons()
        })

        container.innerHTML = data.toast + container.innerHTML
        let xxx = document.querySelectorAll('.bs-toast');
        xxx.forEach(function (element) {
          const toastPlacement = new bootstrap.Toast(element);
          toastPlacement.show()
        })
      }
    })
  })


  let checkSwitchs = document.querySelectorAll('.checkSwitch');
  checkSwitchs.forEach(input => {
    checkCheckSwitchs(input)
    input.addEventListener('input', () => {
      checkCheckSwitchs(input)
    })
  })

  let callTrs = document.querySelectorAll('.call-tr');
  callTrs.forEach(tr => {
    tr.addEventListener('click', () => {
      if (tr.classList.contains('bg-warning')){
        document.querySelectorAll('.call-tr-'+tr.dataset.e164).forEach(function (element) {
          element.classList.remove('bg-warning')
        })
      } else {
        document.querySelectorAll('.call-tr.bg-warning').forEach(function (element) {
          element.classList.remove('bg-warning')
        })
        document.querySelectorAll('.call-tr-'+tr.dataset.e164).forEach(function (element) {
          element.classList.add('bg-warning')
        })        
      }
    })
  })

  let mergeSwitch = document.querySelectorAll('#mergeSwitch');
  mergeSwitch.forEach(input => {
    input.addEventListener('input', () => {
      let contactMerges = document.querySelectorAll('.contact-merge');
      if (input.checked){
        contactMerges.forEach(function (element) {
          element.classList.remove('d-none')
        })
      } else {
        contactMerges.forEach(function (element) {
          element.classList.add('d-none')
        })        
      }
    })
  })



  let selectSwitch = document.querySelectorAll('.select-switch');
  selectSwitch.forEach(button => {
    button.addEventListener('click', () => {
      let select = document.getElementsByClassName(button.id+"_1")[0]
      let input = document.getElementsByClassName(button.id+"_2")[0]
      if (select.classList.contains("hidden")){
        select.classList.remove("hidden")
        input.classList.add("hidden")
        select.name = input.name
        input.name = "select_input__" + input.name
        button.innerHTML = "+"
      }else{
        select.classList.add("hidden")
        input.classList.remove("hidden")
        input.name = select.name
        select.name = "select_input__" + select.name
        button.innerHTML = "-"
      }
    })
  })


  let mainContact = document.querySelectorAll('.mainContact');
  mainContact.forEach(input => {
    input.addEventListener('input', () => {
      let selectContacts = document.querySelectorAll('.selectContacts');
      selectContacts.forEach(function (element) {
        element.classList.remove('d-none')
      })
      input.parentElement.parentElement.querySelector('.selectContacts').classList.add('d-none')
      input.parentElement.parentElement.querySelector('.selectContacts').classList.checked = false
    })
  })

  let selectContacts = document.querySelectorAll('.selectContacts');
  selectContacts.forEach(input => {
    input.addEventListener('input', () => {
      let selectContacts = document.querySelectorAll('.mainContact');
      selectContacts.forEach(function (element) {
        element.classList.remove('d-none')
      })
      if (input.checked){
        input.parentElement.parentElement.querySelector('.mainContact').classList.add('d-none')
        input.parentElement.parentElement.querySelector('.mainContact').classList.checked = false
      }
    })
  })

  let mergeBtns = document.querySelectorAll('.mergeBtn');
  mergeBtns.forEach(btn => {
    btn.addEventListener('click', () => {
      let selectContacts = []
      document.querySelectorAll('.selectContacts:checked:not(.d-none)').forEach(input => {
        selectContacts.push(input.value)
      })

      let mainContact = null
      document.querySelectorAll('.mainContact:checked:not(.d-none)').forEach(input => {
        mainContact = input.value
      })

      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        method: "POST",
        body: JSON.stringify({main_contact_id: mainContact, contact_ids: selectContacts}),
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      }
      fetch("/crm/contacts/merge", options).then(response => {
        if (response.redirected || response.status == 301) {
          window.location.href = response.url;
        } else {
          return response.json()
        }
      }).then((response) => {
        if (response.error) alert(response.error)
      })
    })
  })
  
  let downloadBtn = document.querySelectorAll('.download-btn');
  downloadBtn.forEach(button => {
    button.addEventListener('click', (e) => {
      e.preventDefault();
      button.classList.add("disabled")
      button.innerHTML = '<span class="spinner-border spinner-border-sm" aria-hidden="true" style="margin-top: 3px; margin-right: 5px; width: 14px; height: 14px;"></span>'+button.innerHTML
      fetch(button.href).then(response => response.blob()).then(data => {
        fileDownload(data, button.href.split("/").slice(-1));
        button.innerHTML = button.innerHTML.split("</span>")[1]
        button.classList.remove("disabled")
      })
    })
  })

  
  let codeInput = document.querySelectorAll('.code-input');
  codeInput.forEach(input => {
    input.addEventListener('input', () => {
      const btn = document.getElementById(input.id+'-btn')
      if (input.value === "0815"){
        btn.classList.remove("disabled")
        btn.removeAttribute("disabled")
      } else{
        btn.classList.add("disabled")
        btn.setAttribute("disabled", "disabled")
      }
    })
  })

  let codeInput2 = document.querySelectorAll('.code-input2');
  codeInput2.forEach(input => {
    input.addEventListener('input', () => {
      const btn = document.getElementById(input.id+'-btn')
      if (input.value === "0816"){
        btn.classList.remove("disabled")
        btn.removeAttribute("disabled")
      } else{
        btn.classList.add("disabled")
        btn.setAttribute("disabled", "disabled")
      }
    })
  })
  // Initialize menu togglers and bind click on each
  let menuToggler = document.querySelectorAll('.layout-menu-toggle');
  menuToggler.forEach(item => {
    item.addEventListener('click', event => {
      event.preventDefault();
      window.Helpers.toggleCollapsed();
    });
  });

  // Display menu toggle (layout-menu-toggle) on hover with delay
  let delay = function (elem, callback) {
    let timeout = null;
    elem.onmouseenter = function () {
      // Set timeout to be a timer which will invoke callback after 300ms (not for small screen)
      if (!Helpers.isSmallScreen()) {
        timeout = setTimeout(callback, 300);
      } else {
        timeout = setTimeout(callback, 0);
      }
    };

    elem.onmouseleave = function () {
      // Clear any timers set to timeout
      document.querySelector('.layout-menu-toggle').classList.remove('d-block');
      clearTimeout(timeout);
    };
  };
  if (document.getElementById('layout-menu')) {
    delay(document.getElementById('layout-menu'), function () {
      // not for small screen
      if (!Helpers.isSmallScreen()) {
        document.querySelector('.layout-menu-toggle').classList.add('d-block');
      }
    });
  }

  // Display in main menu when menu scrolls
  let menuInnerContainer = document.getElementsByClassName('menu-inner'),
    menuInnerShadow = document.getElementsByClassName('menu-inner-shadow')[0];
  if (menuInnerContainer.length > 0 && menuInnerShadow) {
    menuInnerContainer[0].addEventListener('ps-scroll-y', function () {
      if (this.querySelector('.ps__thumb-y').offsetTop) {
        menuInnerShadow.style.display = 'block';
      } else {
        menuInnerShadow.style.display = 'none';
      }
    });
  }

  // Init helpers & misc
  // --------------------

  // Init BS Tooltip
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  // Accordion active class
  const accordionActiveFunction = function (e) {
    if (e.type == 'show.bs.collapse' || e.type == 'show.bs.collapse') {
      e.target.closest('.accordion-item').classList.add('active');
    } else {
      e.target.closest('.accordion-item').classList.remove('active');
    }
  };

  const accordionTriggerList = [].slice.call(document.querySelectorAll('.accordion'));
  const accordionList = accordionTriggerList.map(function (accordionTriggerEl) {
    accordionTriggerEl.addEventListener('show.bs.collapse', accordionActiveFunction);
    accordionTriggerEl.addEventListener('hide.bs.collapse', accordionActiveFunction);
  });

  // Auto update layout based on screen size
  window.Helpers.setAutoUpdate(true);

  // Toggle Password Visibility
  window.Helpers.initPasswordToggle();

  // Manage menu expanded/collapsed with templateCustomizer & local storage
  //------------------------------------------------------------------

  // If current layout is horizontal OR current window screen is small (overlay menu) than return from here
  // if (window.Helpers.isSmallScreen()) {
  //   return;
  // }

  // If current layout is vertical and current window screen is > small

  // Auto update menu collapsed/expanded based on the themeConfig
  window.Helpers.setCollapsed(true, false);
  
  const toggleSelectsInnerFunction = function (e) {
    document.querySelectorAll('.content_'+e.id).forEach(ee => {
      ee.classList.add("d-none")
    })
    document.querySelectorAll('.content_'+e.id+'_'+e.value).forEach(ee => {
      ee.classList.remove("d-none")
    })
  }


  let bug_track_notes = document.querySelectorAll('#bug_track_note');
  bug_track_notes.forEach(e => {
    e.addEventListener('input', (event) => {
      document.querySelector("#bug_track_url").value = window.location.href
      document.querySelector("#bug_track_resolution").value = window.innerWidth + "x" + window.innerHeight 
      if (e.value.length > 3){
        document.querySelector("#send_bug_button").disabled = false;
      }else{
        document.querySelector("#send_bug_button").disabled = true;
      }
    })
  })


  let toggleSelects = document.querySelectorAll('.toggle-select');
  toggleSelects.forEach(e => {
    toggleSelectsInnerFunction(e)
    e.addEventListener('change', (event) => {
      toggleSelectsInnerFunction(e)
    })
  })
  let submitOnChange = document.querySelectorAll('.submit-on-change');
  submitOnChange.forEach(e => {
    e.addEventListener('change', (event) => {
      setTimeout(() => {
        e.parentElement.parentElement.submit()
      }, 500);
    })
  })



  let clearButtons = document.querySelectorAll('.clear-button');
  clearButtons.forEach(e => {
    e.addEventListener('click', (event) => {
      e.parentElement.querySelectorAll('.form-select').forEach(s => {
        s.value = "" 
      })
    })
  })

  let consultationButtons = document.querySelectorAll('.btn-consultation');
  consultationButtons.forEach(e => {
    e.addEventListener('click', (event) => {
      if (e.classList.contains("btn-outline-primary")){
        e.classList.replace("btn-outline-primary", "btn-primary")
      } else if (e.classList.contains("btn-primary")){
        e.classList.replace("btn-primary", "btn-outline-primary")
      }
      e.parentElement.querySelector(".btn-consultation:not(#"+e.id+")").classList.replace("btn-primary", "btn-outline-primary")
      e.blur()
    })
  })

}