<template lang="pug">
.card-body.pt-5
  .row
    .col(v-for="d in data.data")
      .row(v-if="!refrech")
        .col-sm-6
          img(:src="d.logo" width="80")
        .col-sm-6.text-end
          h4.mt-2.float-end {{d.date}}
      .row
        .col-4
          .card.mt-4
            .card-body
              .card-title.d-flex.align-items-start.justify-content-between
                .avatar.avatar-initial.rounded.bg-label-primary.p-1
                  svg(viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
                    path(d='M15.58,16.8L12,14.5L8.42,16.8L9.5,12.68L6.21,10L10.46,9.74L12,5.8L13.54,9.74L17.79,10L14.5,12.68M20,12C20,10.89 20.9,10 22,10V6C22,4.89 21.1,4 20,4H4A2,2 0 0,0 2,6V10C3.11,10 4,10.9 4,12A2,2 0 0,1 2,14V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V14A2,2 0 0,1 20,12Z' fill='currentColor')
              strong Gesamt
              div
                .float-end.mt-1: strong.text-danger {{diffCount(d.gastro_count-d.org_gastro_count)}}
                h3.card-title.text-nowrap.mb-1
                  strong {{d.gastro_count}}
        .col-4
          .card.mt-4
            .card-body
              .card-title.d-flex.align-items-start.justify-content-between
                .avatar.avatar-initial.rounded.bg-label-primary.p-1
                  svg(viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
                    path(d='M10.5 19.44C9.75 19.8 8.9 20 8 20C4.69 20 2 17.31 2 14V9C2 5.69 4.69 3 8 3L10.5 7.58C8.1 8.81 6.5 11 6.5 13.5C6.5 16 8.11 18.2 10.5 19.44M16 18.94V20H17C17.55 20 18 20.45 18 21S17.55 22 17 22H14C13.45 22 13 21.55 13 21S13.45 20 14 20V18.94C10.61 18.56 8 16.27 8 13.5C8 10.46 11.13 8 15 8C15.19 8 15.37 8 15.56 8C14.84 6.63 14 5.23 14 4C14 2.9 14.9 2 16 2C18.21 2 20 3.79 20 6H18C18 6 22 9 22 13.5C22 16.27 19.39 18.56 16 18.94M16 4C16 4.55 16.45 5 17 5S18 4.55 18 4 17.55 3 17 3 16 3.45 16 4M17 12C17 10.9 16.11 10 15 10C12.79 10 11 11.79 11 14H15C16.11 14 17 13.11 17 12Z' fill='currentColor')
              strong Ente
              div
                .float-end.mt-1: strong.text-danger {{diffCount((d.gastro_count-d.vegan_count)-(d.org_gastro_count-d.org_vegan_count))}}
                h3.card-title.text-nowrap.mb-1
                  strong {{d.gastro_count-d.vegan_count}}
        .col-4
          .card.mt-4
            .card-body
              .card-title.d-flex.align-items-start.justify-content-between
                .avatar.avatar-initial.rounded.bg-label-primary.p-1
                  svg(viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
                    path(d='M2,22V20C2,20 7,18 12,18C17,18 22,20 22,20V22H2M11.3,9.1C10.1,5.2 4,6.1 4,6.1C4,6.1 4.2,13.9 9.9,12.7C9.5,9.8 8,9 8,9C10.8,9 11,12.4 11,12.4V17C11.3,17 11.7,17 12,17C12.3,17 12.7,17 13,17V12.8C13,12.8 13,8.9 16,7.9C16,7.9 14,10.9 14,12.9C21,13.6 21,4 21,4C21,4 12.1,3 11.3,9.1Z' fill='currentColor')
              strong Vegan
              div
                .float-end.mt-1: strong.text-danger {{diffCount(d.vegan_count-d.org_vegan_count)}}
                h3.card-title.text-nowrap.mb-1
                  strong {{d.vegan_count}}
      .row.mt-2
        .col-12: .alert.alert-info.p-1.m-0.mt-2(v-for="c in kitchenComments(d)" style="box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12);" :class="{blink_me: currentTime - c.time - 60 * 5 < 0}")
          strong.m-0(style="font-size: 11px; color: #000;" v-html="c.text")
          .text-end: small(style="font-size: 9px; color: #000;") {{c.info}}
</template>

<script>
export default {
  components: {
  },
  props: [
    "_data",
    "screen_id",
    "refrech"
  ],
  channels: (window.location.href.indexOf("screen_3") === -1) ? {} : {
    GastroChannel: {
      received(data) { 
        this.fetch()
      },
    },
    GastroScreenChannel: {
      received(id) { 
        window.location.href = `/ticketing/roomplan/${id}?ipad=true&list=true&screen_id=${this.screen_id}`
      },
    }
  },
  data () {
    return {
      data: {},
      currentTime: 0,
      currentPage: 0
    }
  },
  mounted () {
    this.data = this._data

    this.updateTime()
    let self = this
    window.setInterval(function(){
      self.updateTime()
    }, 1000 * 20);

    window.setInterval(function(){
      self.currentPage = self.currentPage + 1
    }, 1000 * 10);

    if (!this.refrech){
      this.subscribe()
      if (this.screen_id){
        this.$cable.subscribe({
          channel: "TicketingChannel::GastroScreenChannel",
          screen_id: this.screen_id.split(":")[0]
        }, "GastroScreenChannel");
      }

      document.addEventListener("visibilitychange", () => {
        if (!document.hidden) this.fetch()
      });
    }
  },
  watch: {
    refrech(){
      this.data = this._data
    }
  },
  computed: {
  },
  methods: {
    kitchenComments(d){
      const maxPage = Math.ceil(d.kitchen_comments.length / 5)
      return d.kitchen_comments.slice(5 * ((this.currentPage%maxPage)), 5 * (this.currentPage%maxPage+1))
    },    
    updateTime(){
      const d = new Date();
      let time = d.getTime();
      this.currentTime = time / 1000
    },    
    diffCount(x){
      if (x > 0){
        return "(+"+x+")"
      } else if (x < 0){
        return "("+x+")"
      }
    },
    subscribe(){
      this.data.data.forEach( x => {
        this.$cable.subscribe({
          channel: "TicketingChannel::GastroChannel",
          token: x.channel_token
        }, "GastroChannel");
      })
    },
    fetch(){
      fetch(window.location.href.split("?")[0]+".json?screen_id="+this.screen_id).then(x => x.json()).then((response) => {
        this.data = response
      })     
    }
  }
}
</script>

<style lang="scss">
</style>