import { createApp } from 'vue'
import App from './App.vue'
import ActionCableVue from "actioncable-vue";

const actionCableVueOptions = {
  debug: false
};

document.addEventListener("turbo:load", () => {
  const mountEl = document.querySelector("#orderbird");
  if (mountEl){
    const app = createApp(App, { ...mountEl.dataset, innerHtml: mountEl.innerHTML })
    app.use(ActionCableVue, actionCableVueOptions).mount('#orderbird')
  }
});

